@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html, body {
    /* background-color: #333232; */
}
::-webkit-scrollbar{
    width:18px;
  }
  ::-webkit-scrollbar-track{
    background-color:rgb(68, 67, 67);
    /* border:7px solid var(--dark-color); */
    /* box-shadow: inset 0 0 2.5px 2px;  */
  }
  ::-webkit-scrollbar-thumb{
    background:rgb(143, 143, 145);
    border:3px solid rgb(120, 120, 122);
  }

.modal-height {
    height:60vh !important;
    overflow-y: scroll !important;
}
.console-zoom-in {
    animation: zoom-in 0.5s ease-in forwards;
}
@keyframes zoom-in {
    0% {
    transform: scale(0.1, 0.1);
    }
    100% {
    transform: scale(1, 1);
    }
}

.bg-red {
    background-color: rgb(171, 11, 11);
}

.bg-window {
    background-color: rgb(171, 11, 11);
    opacity:0.9;
    color: #ffffff;
}
.bg-console {
    background-color: #000000;
}

.instruction-text__item {
    font-family: 'Roboto Mono', monospace !important;
    color:#ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    /* margin: auto 2rem; */
}

.instruction-text__button {
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    border:5px #04c9c5 solid;
    color:#ffffff;
    padding: 0.75rem;
    background-color: rgb(25, 27, 25);
    opacity:0.8;
    border-radius: 15px;
    font-size: x-large;
    text-decoration: none;
    text-transform:uppercase;
    animation: pulse 1s ease-in forwards;
    animation-iteration-count: infinite;
}
    .instruction-text__button:hover {
        color:#0bdf08;
        animation: none;
        border:7.5px #0bdf08 solid;   
    }
    .instruction-text__button.no-btn:hover {
        color:#bb4141;
        animation: none;
        border:7.5px #bb4141 solid;   
    }
    .instruction-text__button:hover:after {
        font-family: 'Roboto Mono', monospace;
        font-weight: bold;
        border:5px #04c9c5 solid;
        color:#ffffff;
        padding: 0.75rem;
        background-color: rgb(25, 27, 25);
        opacity:0.8;
        border-radius: 15px;
        font-size: x-large;
        text-decoration: none;
        text-transform:uppercase;
        animation: pulse 1s ease-in forwards;
        animation-iteration-count: infinite; 
    }
@keyframes pulse {
    0% {
        background-image: linear-gradient(rgb(25, 27, 25), #04c9c5);
    }
    10% {
        background-image: linear-gradient(rgb(25, 27, 25), #05b7b4);
    }
    20% {
        background-image: linear-gradient(rgb(25, 27, 25), #05a5a3);
    }
    30% {
        background-image: linear-gradient(rgb(25, 27, 25), #049491);
    }
    40% {
        background-image: linear-gradient(rgb(25, 27, 25), #04817f);
    }
    50% {
        background-image: linear-gradient(rgb(25, 27, 25), #0e5c5b);
    }
    60% {
        background-image: linear-gradient(rgb(25, 27, 25), #04817f);
    }
    70% {
        background-image: linear-gradient(rgb(25, 27, 25), #049491);
    }
    80% {
        background-image: linear-gradient(rgb(25, 27, 25), #05a5a3);
    }
    90% {
        background-image: linear-gradient(rgb(25, 27, 25), #05b7b4);
    }
    100% {
        background-image: linear-gradient(rgb(25, 27, 25), #04c9c5);
    }
}

.instruction-text__button_old {
    font-family: 'Roboto Mono', monospace;
    color:#ffffff;
    padding: 0.75rem;
    background-color: rgb(9, 96, 9);
    border-radius: 15px;
    font-size: x-large;
    text-decoration: none;
}
    .instruction-text__button_old:hover {
        color:#ffffff;
    }


.appear-3 {
    animation: appear 3s ease-in forwards;
}
.appear-4 {
    animation: appear 4s ease-in forwards;
}
.appear-5 {
    animation: appear 5s ease-in forwards;
}
.appear-7 {
    animation: appear 7s ease-in forwards;
}
.appear-9 {
    animation: appear 9s ease-in forwards;
}
.appear-12 {
    animation: appear 12s ease-in forwards;
}
.appear-13 {
    animation: appear 13s ease-in forwards;
}
.appear-14 {
    animation: appear 14s ease-in forwards;
}
.appear-15 {
    animation: appear 15s ease-in forwards;
}
.appear-16 {
    animation: appear 16s ease-in forwards;
}
.appear-17 {
    animation: appear 17s ease-in forwards;
}
.appear-18 {
    animation: appear 18s ease-in forwards;
}
.appear-19 {
    animation: appear 19s ease-in forwards;
}
.appear-20 {
    animation: appear 20s ease-in forwards;
}
.appear-21 {
    animation: appear 21s ease-in forwards;
}
.appear-22 {
    animation: appear 22s ease-in forwards;
}
.appear-23 {
    animation: appear 23s ease-in forwards;
}
.appear-25 {
    animation: appear 25s ease-in forwards;
}
.appear-27 {
    animation: appear 27s ease-in forwards;
}
.appear-33 {
    animation: appear 33s ease-in forwards;
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    89% {
        opacity: 0;
    }
    90% {
        opacity: 0.2;
        background-color: #000000;
    }
    95% {
        opacity: 0.5;
        background-color: rgb(7, 55, 7);
    }
    100% {
        opacity: 1;
    }
}

.text-white {
    color: #ffffff;
}

.background-black {
    background-color: #000000;
}

.background-gray {
    background-color: #333232;
}

.video-offcanvas {
    height: 95vh !important;
}

.ver-top-row {
    height: 30% !important;
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

.ver-bottom-row {
    height: 70vh !important;
    max-height: 70vh !important;
}

.riddle-input {
    text-align:center;
    font-size:x-large;
}

.riddle-input.incorrect {
    background-color: rgb(225, 153, 153);
    border-color: red;
    color: red;
    font-weight: bold;
    animation: shakeInput 0.5s linear forwards;
}
@keyframes shakeInput {
    0% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(5%);
    }
    66% {
        transform: translateX(-5%);
    }
    100% {
        transform: translateX(0);
    }
}

.riddle-input.correct {
    background-color: rgb(0, 0, 0) !important;
    border-color: #ffffff !important;
    color: rgb(0, 255, 34) !important;
    font-weight: bold !important;
    animation: expandInput 0.5s linear forwards;
}
@keyframes expandInput {
    0% {
        transform: scale(100%);
    }
    50% {
        transform: scale(105%);
    }
    100% {
        transform: scale(110%);
    }
}

.hint-flicker1 {
    animation: hintFlicker 2s linear forwards;
}

.hint-flicker2 {
    animation: hintFlicker 2s linear forwards;
}

.hint-flicker3 {
    animation: hintFlicker 2s linear forwards;
}

@keyframes hintFlicker {
    0% {
        color:red;
    }
    20% {
        color:black;
    }
    40% {
        color:red;
    }
    60% {
        color:black;
    }
    80% {
        color:red;
    }
    100% {
        color:black;
    }
}

.incoming-call-flicker {
    animation: incomingCallFlicker 4s linear forwards;
}
@keyframes incomingCallFlicker {
    0% {
        color:white;
    }
    20% {
        color:black;
    }
    40% {
        color:white;
    }
    60% {
        color:black;
    }
    80% {
        color:white;
    }
    100% {
        color:black;
    }
}

.hidden {
    display:none;
}

.btn-ens {
    background-color: rgb(0, 255, 34);
    color: #000000;
    font-weight: bold;
    border-radius: 10px;
    font-size:medium;
    padding:10px;
}


.fade-out-1 {
    animation: fade-out 1s ease-in forwards;
}
.fade-out-3 {
    animation: fade-out 3s ease-in forwards;
}
.fade-out-5 {
    animation: fade-out 5s ease-in forwards;
}
.fade-out-7 {
    animation: fade-out 7s ease-in forwards;
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0.9;
    }
    20% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity:0;
    }
}

.delay-hide-1 {

}

.fade-in-1 {
    animation: fade-in 1s ease-in forwards;
}
.fade-in-3 {
    animation: fade-in 3s ease-in forwards;
}
.fade-in-5 {
    animation: fade-in 5s ease-in forwards;
}
.fade-in-7 {
    animation: fade-in 7s ease-in forwards;
}
@keyframes fade-in {
    0% {
        opacity: 0;
        display: block;
    }
    10% {
        opacity: 0.1;
    }
    20% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.phaseshift-enter {
    
}
.phaseshift-enter-active {
    animation: fade-in 1s ease-in forwards;
}
.phaseshift-exit {
    
}
.phaseshift-exit-active {
    animation: fade-out 1s ease-in forwards;
}


.answer-call-button {
    background-color: #27d32f;
    color: white;
    border: none;
    padding: 5px;
    font-size: 31px;
    height: 130px;
    width: 130px;
    box-shadow: 0 8px 10px #147018;
    cursor: pointer;
    border-radius: 70%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.start-game-button {
    background-color: #27d32f;
    color: white;
    border: none;
    padding: 5px;
    font-size: 31px;
    height: 130px;
    width: 130px;
    box-shadow: 0 8px 10px #147018;
    cursor: pointer;
    border-radius: 70%;
}


.challenge-2-input {
    background-color:white;
    color:black;
    width:50px;
    height:75px;
    font-size: 60px;
}

.challenge-3-input {
    background-color:white;
    color:black;
    width:300px;
    height:60px;
    font-size: 40px;
}

.cocktail-height {
    max-height:35%;
}

.year-input-height {
    max-height: 30%;
}

.scroll-x {
    overflow-x: auto;
}

.challenge-2-incorrect {
    background-color: rgb(225, 153, 153);
    border-color: red;
    color: red;
    font-weight: bold;
    animation: shakeInput 0.5s linear forwards;
}

.console-icon {
    max-height:90px;
}

.rounded-top-corners {
    border-top-left-radius: var(--bs-border-radius-lg)!important;;
    border-top-right-radius: var(--bs-border-radius-lg)!important;;
}

.team-info-form__input {
    color:#000000;
    background-color: #ffffff;
    border: #ffffff 5px solid;
    width:80%;
    font-size: x-large;
}

.forfeit {
    background-color:red !important;
}

.spinner-svg {
    fill:none;
    width: 80px;
    overflow: visible;
    stroke: #fff;
    stroke-width: 4;
    stroke-linejoin: round;
    stroke-linecap: round;
  }